import { FEButton } from 'components';
import {
  IMG_IC_24_TG,
  IMG_IC_24_YT,
  IMG_IC_24_OK,
  IMG_IC_24_VK,
  //
  IMG_PLACEHOLDER_APPLE,
  IMG_PLACEHOLDER_GOOGLE,
  IMG_PLACEHOLDER_HUAWEI,
} from 'images';
import style from './style.module.css';
import { useMediaQuery } from 'ui-kit/hooks';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import {
  APP_GALLERY_LINK,
  APP_STORE_LINK,
  CONTACTS_PATHNAME,
  DELIVERY_AND_PAYMENTS_PATHNAME,
  FEEDBACK_EMAIL,
  GOOGLE_PLAY_LINK,
  HOTLINE_PHONE_NUMBER,
  LEGAL_INFORMATION_PATHNAME,
  PROMOTIONS_PATHNAME,
  RESTAURANTS_PATHNAME,
  //
} from 'const';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import { $sitesMap } from 'domains/sitesMap';

const Footer = () => {
  const isDesktop = useMediaQuery(`(min-width: ${1024}px)`);
  const { t } = useTranslation();

  const location = useLocation();

  const [sitesMap] = useUnit([$sitesMap]);

  return (
    <div className={style.footer}>
      <div className={style.wrapper}>
        {!isDesktop ? (
          <>
            <div className={style.dataContainer}>
              <div className={style.dataItem}>
                <p className='headline'>{t('footer.data.hotlineLabel')}</p>
                <a
                  className={clsx('headline', style.dataValue)}
                  href={`tel:${HOTLINE_PHONE_NUMBER}`}
                >
                  {HOTLINE_PHONE_NUMBER}
                </a>
              </div>
              <div className={style.dataItem}>
                <p className='headline'>{t('footer.data.feedbackLabel')}</p>
                <a
                  className={clsx('headline', style.dataValue)}
                  href={`mailto:${FEEDBACK_EMAIL}`}
                >
                  {FEEDBACK_EMAIL}
                </a>
              </div>
            </div>
            <div className={style.accountsContainer}>
              {sitesMap?.youtube && (
                <FEButton
                  className={style.accountButton}
                  onClick={() => window.open(sitesMap.youtube, '_blang')}
                  type='secondary'
                >
                  <img src={IMG_IC_24_YT} alt='' />
                </FEButton>
              )}
              {sitesMap?.ok && (
                <FEButton
                  className={style.accountButton}
                  onClick={() => window.open(sitesMap.ok, '_blang')}
                  type='secondary'
                >
                  <img src={IMG_IC_24_OK} alt='' />
                </FEButton>
              )}
              {sitesMap?.telegram && (
                <FEButton
                  className={style.accountButton}
                  onClick={() => window.open(sitesMap.telegram, '_blang')}
                  type='secondary'
                >
                  <img src={IMG_IC_24_TG} alt='' />
                </FEButton>
              )}
              {sitesMap?.vk && (
                <FEButton
                  className={style.accountButton}
                  onClick={() => window.open(sitesMap.vk, '_blang')}
                  type='secondary'
                >
                  <img src={IMG_IC_24_VK} alt='' />
                </FEButton>
              )}
            </div>
            <div className={style.information}>
              <p className='headline'>{t('footer.information.label')}</p>
              <div className={style.informationList}>
                {sitesMap?.franchise && (
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={sitesMap.franchise}
                    className={style.informationListItem}
                  >
                    {t('footer.information.franchise')}
                  </a>
                )}
                <Link
                  className={style.informationListItem}
                  to={`/${DELIVERY_AND_PAYMENTS_PATHNAME}`}
                  onClick={() =>
                    window.scrollTo({
                      top: 0,
                    })
                  }
                >
                  {t('footer.information.deliveryAndPayment')}
                </Link>
                <Link
                  className={style.informationListItem}
                  to={`/${CONTACTS_PATHNAME}`}
                  onClick={() =>
                    window.scrollTo({
                      top: 0,
                    })
                  }
                >
                  {t('footer.information.contacts')}
                </Link>
                <Link
                  className={style.informationListItem}
                  to={`/${RESTAURANTS_PATHNAME}`}
                  onClick={() =>
                    window.scrollTo({
                      top: 0,
                    })
                  }
                >
                  {t('footer.information.restaurants')}
                </Link>
                {sitesMap?.job && (
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={sitesMap.job}
                    className={style.informationListItem}
                  >
                    {t('footer.information.vacancies')}
                  </a>
                )}
                <Link
                  className={style.informationListItem}
                  to={`${location.pathname}/${PROMOTIONS_PATHNAME}`}
                >
                  {t('footer.information.promotions')}
                </Link>
              </div>
            </div>
            <div className={style.apps}>
              <p className='headline'>
                {t('footer.apps.label', { companyName: 'BlinBerry' })}
              </p>
              <div className={style.appsList}>
                <button
                  className={style.appLink}
                  onClick={() => window.open(APP_STORE_LINK, '_blank')}
                >
                  <img src={IMG_PLACEHOLDER_APPLE} alt='' />
                </button>
                <button
                  className={style.appLink}
                  onClick={() => window.open(GOOGLE_PLAY_LINK, '_blank')}
                >
                  <img src={IMG_PLACEHOLDER_GOOGLE} alt='' />
                </button>
                <button
                  className={style.appLink}
                  onClick={() => window.open(APP_GALLERY_LINK, '_blank')}
                >
                  <img src={IMG_PLACEHOLDER_HUAWEI} alt='' />
                </button>
              </div>
            </div>
            <div className={style.legalInformation}>
              <div className={style.legalInformationWrapper}>
                <div className={style.legalInformationContainer}>
                  <p className={clsx('caption-1', style.legalInformationValue)}>
                    Blinberry Marina Restaurant LLC
                  </p>
                  <Link
                    to={`${location.pathname}/${LEGAL_INFORMATION_PATHNAME}`}
                    className={clsx('caption-1', style.legalInformationLink)}
                  >
                    {t('footer.legalInformation.linkLabel')}
                  </Link>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={style.mainDataWrapper}>
              <div className={style.dataContainer}>
                <div className={style.dataItems}>
                  <div className={style.dataItem}>
                    <p className='headline'>{t('footer.data.hotlineLabel')}</p>
                    <a
                      className={clsx('headline', style.dataValue)}
                      href={`tel:${HOTLINE_PHONE_NUMBER}`}
                    >
                      {HOTLINE_PHONE_NUMBER}
                    </a>
                  </div>
                  <div className={style.dataItem}>
                    <p className='headline'>{t('footer.data.feedbackLabel')}</p>
                    <a
                      className={clsx('headline', style.dataValue)}
                      href={`mailto:${FEEDBACK_EMAIL}`}
                    >
                      {FEEDBACK_EMAIL}
                    </a>
                  </div>
                </div>
                <div className={style.accountsContainer}>
                  {sitesMap?.youtube && (
                    <FEButton
                      className={style.accountButton}
                      onClick={() => window.open(sitesMap.youtube, '_blang')}
                      type='secondary'
                    >
                      <img src={IMG_IC_24_YT} alt='' />
                    </FEButton>
                  )}
                  {sitesMap?.ok && (
                    <FEButton
                      className={style.accountButton}
                      onClick={() => window.open(sitesMap.ok, '_blang')}
                      type='secondary'
                    >
                      <img src={IMG_IC_24_OK} alt='' />
                    </FEButton>
                  )}
                  {sitesMap?.telegram && (
                    <FEButton
                      className={style.accountButton}
                      onClick={() => window.open(sitesMap.telegram, '_blang')}
                      type='secondary'
                    >
                      <img src={IMG_IC_24_TG} alt='' />
                    </FEButton>
                  )}
                  {sitesMap?.vk && (
                    <FEButton
                      className={style.accountButton}
                      onClick={() => window.open(sitesMap.vk, '_blang')}
                      type='secondary'
                    >
                      <img src={IMG_IC_24_VK} alt='' />
                    </FEButton>
                  )}
                </div>
              </div>
              <div className={style.information}>
                <p className='headline'>{t('footer.information.label')}</p>
                <div className={style.informationList}>
                  {sitesMap?.franchise && (
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href={sitesMap.franchise}
                      className={style.informationListItem}
                    >
                      {t('footer.information.franchise')}
                    </a>
                  )}
                  <Link
                    className={style.informationListItem}
                    to={`/${DELIVERY_AND_PAYMENTS_PATHNAME}`}
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                      })
                    }
                  >
                    {t('footer.information.deliveryAndPayment')}
                  </Link>
                  <Link
                    className={style.informationListItem}
                    to={`/${CONTACTS_PATHNAME}`}
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                      })
                    }
                  >
                    {t('footer.information.contacts')}
                  </Link>
                  <Link
                    className={style.informationListItem}
                    to={`/${RESTAURANTS_PATHNAME}`}
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                      })
                    }
                  >
                    {t('footer.information.restaurants')}
                  </Link>
                  {sitesMap?.job && (
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href={sitesMap.job}
                      className={style.informationListItem}
                    >
                      {t('footer.information.vacancies')}
                    </a>
                  )}
                  <Link
                    className={style.informationListItem}
                    to={`${location.pathname}/${PROMOTIONS_PATHNAME}`}
                  >
                    {t('footer.information.promotions')}
                  </Link>
                </div>
              </div>
              <div className={style.apps}>
                <p className='headline'>
                  {t('footer.apps.label', { companyName: 'BlinBerry' })}
                </p>
                <div className={style.appsList}>
                  <button
                    className={style.appLink}
                    onClick={() => window.open(APP_STORE_LINK, '_blank')}
                  >
                    <img src={IMG_PLACEHOLDER_APPLE} alt='' />
                  </button>
                  <button
                    className={style.appLink}
                    onClick={() => window.open(GOOGLE_PLAY_LINK, '_blank')}
                  >
                    <img src={IMG_PLACEHOLDER_GOOGLE} alt='' />
                  </button>
                  <button
                    className={style.appLink}
                    onClick={() => window.open(APP_GALLERY_LINK, '_blank')}
                  >
                    <img src={IMG_PLACEHOLDER_HUAWEI} alt='' />
                  </button>
                </div>
              </div>
            </div>
            <div className={style.legalInformation}>
              <div className={style.legalInformationWrapper}>
                <div className={style.legalInformationContainer}>
                  <p className={clsx('caption-1', style.legalInformationValue)}>
                    Blinberry Marina Restaurant LLC
                  </p>
                  <Link
                    to={`${location.pathname}/${LEGAL_INFORMATION_PATHNAME}`}
                    className={clsx('caption-1', style.legalInformationLink)}
                  >
                    {t('footer.legalInformation.linkLabel')}
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Footer;
